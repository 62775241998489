import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

//RPB-NOTE will probably not run since JQuery interferes with ruby UI
jQuery(window).on("load", function () {
  const buttons = document.querySelectorAll(".btn");
  buttons.forEach(function (currentBtn) {
    const default_click = currentBtn.onclick ? currentBtn.onclick : null;
    currentBtn.onclick = function (event) {  //RPB-EDIT need to pass event arg
      event.preventDefault(); //RPB-EDIT  to stop double clicks
      if (default_click) {
        default_click();
      }
      if (this.form) {
        this.disabled = true;
        this.value = "Sending, please wait...";
        this.form.submit();
      }
    };
  });
});
